import * as React from "react"
import { graphql } from "gatsby"
import { Container, Box, Text, Section, Flex, Subhead, Heading, SubheadSmall, } from "../ui"
import { StaticImage } from "gatsby-plugin-image"
import {  spancolor, spancolor_8, lightcolor_5,  marginBottom_4, marginBottom_1, text_justify } from '../ui.css'
import platform_banner from '../../images/platform_images/platform_banner.png'
export default function Insight_platform(props) {
    return (
        <Section >
            <Container style={{ color: "#2D0E4A" }}  width="narrow2" >
                <Flex gap={4} variant="responsive" >
                    <Box >
                        <Subhead center className={marginBottom_4}>
                            <StaticImage src='../../images/Skill_elevator_icon.png' alt="Skill_elevator_icon" width={20} style={{ marginTop: "1%" }} /> <span className={spancolor_8}>Insight</span> About Platform
                        </Subhead>
                    </Box>
                </Flex>
                <Flex gap={4} variant="responsive" className={marginBottom_1}>
                    <Box>
                        <StaticImage src='../../images/platform_images/online_platform.png' alt="online_platform" />
                    </Box>
                    <Box>
                        <Container>
                            <Subhead> <span className={spancolor_8}> <StaticImage src='../../images/Skill_icon.png' alt="Skill_elevator_icon" width={20} style={{ marginTop: "2%" }} />Online Platform </span>with Actual Work Experience</Subhead>
                            <Text className={`${lightcolor_5} ${text_justify}`}>Bizzlab is an online/web platform to provide actual work experience in a simulated way. It means, In the virtual environment, one can get job-related experience by logining into our modules. Bizzlab is focused on finance and commerce students who do not have practical training options in their colleges. It is India's first online platform which has created entire work experience as 'Business Laboratory' modules for commerce students which contains bundles of actual transactions that are generally used in any mid-sized company/industry. In other words, students have been given a simulated working platform and virtual company to work in and get proper experience as if he is working in any company..</Text>
                        </Container>
                    </Box>
                </Flex>
            </Container>
            <Container style={{ color: "#2D0E4A" }}  width="narrow2">
                <Flex gap={4} variant="responsive">
                    <Box>
                        <Container>
                            <Subhead> <span className={spancolor_8}>Work Modules</span> <StaticImage src='../../images/Skill_icon.png' alt="Skill_elevator_icon" width={20} style={{ marginTop: "2%" }} /><br/>
                                for Various Job Roles</Subhead>
                            <Text className={`${lightcolor_5} ${text_justify}`}>Bizzlab is also designed as work modules for various Jobs that are suitable for any fresher who can get exposed to the corporate  works by working on our platforms. After Successful completion of all modules one can get a certificate of practical training and they will get good confidence to attend any level of interviews. Every student who is pursuing B.Com/BBA/BBM/M.Com/MBA and are not been exposed to the practical work as no college in India is offering hands-on vocational training to commerce students. Due to this students are struggling to get succeed in an interview after completion of their Graduation/Post graduation.
                            </Text>
                        </Container>
                    </Box>
                    <Box>
                        <StaticImage src='../../images/platform_images/online_platforms.png' alt="online_platforms" />
                    </Box>
                </Flex>
            </Container>
            <Container style={{ color: "#2D0E4A" }}  width="narrow2">
                <Flex gap={4} variant="responsive">
                    <Box>
                        <StaticImage src='../../images/platform_images/virtual_job_experience.png' alt="virtual_job_experience" />
                    </Box>
                    <Box>
                        <Container>
                            <Subhead> <span className={spancolor_8}>Virtual</span> <StaticImage src='../../images/Skill_icon.png' alt="Skill_elevator_icon" width={20} style={{ marginTop: "2%" }} /><br/>
                                Job Experience</Subhead>
                            <Text className={`${lightcolor_5} ${text_justify}`} variant="lead">At the same time opportunity to work in a company for experience is not that easy and at the same time getting exposure to various areas really consumes many years. To address this issue, Bizzlab has created online modules that have been placed in a virtual company environment in which students can simulate and learn practical works. Because it is an online platform, any student or job seeker can login and use our modules at any time and speed up their complete working knowledge.	
                            </Text>
                        </Container>
                    </Box>
                </Flex>
            </Container>
        </Section>
    )
}
