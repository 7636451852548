import * as React from "react"
import { Container, Box, Text, Section, Flex, Heading, PlatformHeading, PlatformSubhead, FlexList, Subhead } from "../ui"
import { StaticImage } from "gatsby-plugin-image"
import { about_hero, about_hero_img, lightcolor, marginBottom, marginBottom_4, paddingTop, spancolor_5,text_justify } from "../ui.css"
export default function Work_based(props) {
    return (
        <>
            <Section style={{ background: "radial-gradient(88.77% 152.19% at 12.8% -49.02%, #2B237C 0%, #251C72 31%, #0F054C 100%)", transform: "skewY(-6deg)", color: "#fff" }}>
                <Container style={{ transform: "skewY(6deg)" }} width="narrow2">
                    <Flex gap={4} variant="responsive" className={paddingTop}>
                        <Box center>
                            <FlexList>
                            <li>   <Text><StaticImage src='../../images/Skill_icon.png' alt="Skill_elevator_icon" width={30} style={{ marginTop: "34%" }} /> </Text></li>
                                <li>
                                    <Subhead className={spancolor_5}>
                                        Industry Work based Modules
                                    </Subhead>
                                </li>
                            
                            </FlexList>
                            <PlatformSubhead className={marginBottom_4}>
                                The best platform for commerce skilling & job enhancement.
                            </PlatformSubhead>
                            <Text></Text>
                        </Box>
                    </Flex>
                </Container>
                <Container style={{ transform: "skewY(6deg)" }} width="narrow2">
                    <Flex gap={4} variant="responsive">
                        <Box>
                        <Container>
                            <FlexList>
                                <li>
                                    <Subhead>
                                        Business Laboratory
                                    </Subhead>
                                </li>
                                <li> <Text><StaticImage src='../../images/Skill_icon.png' alt="Skill_elevator_icon" width={20} style={{ marginTop: "48%" }} /> </Text></li>
                            </FlexList>
                            <Text className={text_justify}>
                              
                                Business laboratory is a consolidation of 20 modules that represents various transactional work in every company or business. The Objective of this paltform is to give an opportunity to students to undergo needed learnning on realtime works and feel like working in a company. It is a laboratory for Business in a way that enable students to experiement themselves with virual transactions in the business. Students need to register each module and complete the module, one after one.after registration, each subscriber will be given an opportunity to work with one virtual company. Each module give a feel of real time office/ work place job and subscriber will be given limited level of transactions in the screen. As an example, 'Practical Accounting and Book Keeping' module gives an insight in to the accounting entries using various vouchers and bill. It gives the complete work experience in accounting.
                             
                              
                            </Text>
                            </Container>
                        </Box>
                        <Box>
                            <Text>
                                <StaticImage src="../../images/biz_lab_images/biz_lab_banner.png" alt="biz_lab_banner" />
                            </Text>
                        </Box>
                    </Flex>
                </Container>
            </Section>
            <Section></Section>
        </>
    )
}
