import * as React from "react"
import {
    Box, Container, Flex, Heading, PlatformHeading, Text,
    PlatformSubhead, Section, PlatformPlainText, CardSection, Card,
    CardHeader, CardBody, FeatureCard, FlexList, Subhead, Link, Button
} from "../../components/ui";
import { FiChevronRight } from "react-icons/fi";
import { StaticImage } from "gatsby-plugin-image";
import { BsCheckCircleFill } from "react-icons/bs";
import * as styles from "../../components/ui.css.ts"
import { referralBonusPoints } from "../../constants/platform";
import { black_color, lightcolor_5, paddingTop, spancolor, border_radius, bold, gardient_btns, marginTop, marginBottom, platform_btn } from "../ui.css";
const Subscrib = () => (
    <>
        <Section>
        </Section>
        <Section>
            <Container width="narrow2">
                <Flex gap={4} variant="responsive">
                    <Box>
                        <Text> <Button className={platform_btn}><StaticImage src="../../images/skill_icon.png" transformOptions={{ fit: "cover", cropFocus: "attention" }} alt="skill_elevator_icon" width={20} /> &nbsp; How to Access our Platform</Button></Text>
                        <Heading style={{ color: '#081F32', fontWeight: '550', fontSize: '40px' }}>
                            Login Creation
                        </Heading>
                        <PlatformPlainText >
                            The first step to access our platform is to create login credentials. Please click below to create login. You need to provide few details in the login screen and after successful submission, login credential will be shared to your email. Using those credentials you can login and view the platform.
                        </PlatformPlainText>
                        {/* <StaticImage src='../images/platform/createLogin.png' alt="createLogin" width={150} /> */}
                        <Button className={marginTop}><Link to="/Login">Create Login </Link><FiChevronRight /></Button>
                    </Box>
                    <Box>
                        <StaticImage src='../images/loginCreation.png' alt="login_creation" width={750} />
                    </Box>
                </Flex>
            </Container>
        </Section>
        <Section style={{ background: "radial-gradient(88.77% 227.16% at 12.81% -73.17%, #2B237C 0%, #251C72 31%, #0F054C 100%)", color: "#FFF", transform: "skewY(-5deg)" }}>
            <Container style={{ transform: "skewY(5deg)", }} width="narrow2">
                <Flex gap={4} variant="responsive">
                    <Box>
                        <Subhead style={{ color: '#FFFFFF', fontWeight: '550', fontSize: '40px' }}>
                            <span style={{ color: '#00D4FF' }}>Registration </span> & <br /> Payment &nbsp;
                            <StaticImage src="../../images/Skill_icon.png" width={30} style={{ marginTop: "2%" }} />
                        </Subhead>
                        <PlatformPlainText style={{ color: "#fff" }}>
                            The next step is to register in the platform. The registration process is very simple. Click on the registration menu in the platform and fill in the details. Provide your name, address qualification, etc., and upload your photo. After submission, it takes you to the payment screen where you need to pay the Registration fee and another module fee. For certain eligible students, the registration fee is not applicable and even modules also will get at a concessional price. The payment module shows all these details. One needs to select the modules from the list and click for payment which takes to the 'Payment gateway'. We have different payment gateway options available such as Paytm, google pay, credit card, debit card, Net banking, etc.
                            Using one of the options students can pay. After successful payment students will get their registration number and payment receipt to their official email ID.
                        </PlatformPlainText>
                    </Box>
                    <Box>
                        <StaticImage src='../images/platform/reg_and_payment.png' alt="reg_and_payment" width={900} />
                    </Box>
                </Flex>
            </Container>
        </Section>

        <Container width="narrow2" >
            <Flex gap={4} variant="responsive">
                <Box className={black_color}>
                    <Subhead className={paddingTop}>
                        <span className={spancolor}> How <StaticImage src='../../images/Skill_icon.png' alt="Skill_elevator_icon" width={30} style={{ marginTop: "1%" }} /></span><br /> Platform Works
                    </Subhead>
                    <Text className={lightcolor_5}>
                        We have two platforms available - viz (a) Business Laboratory  and (b) Job Simulator. One registration is enough to access two platforms. However, as process student would be allowed to access business labortatory at first and after completion of all modules, they will get the access to Job Simulator. In otherwords, system allows in sequential manner.
                    </Text>
                </Box>
            </Flex>
        </Container>

        <Section>
            <Container className={black_color} width="narrow2">
                <Flex gap={4} variant="responsive">
                    <Box>
                        <StaticImage src='../images/platform/how_platform_works.png'
                            alt="how_platform_works" width={1155} />
                        <PlatformSubhead className={lightcolor_5}>
                            <Text>  When student login in to the platform, </Text>
                            <Text> it takes to Business laboratory platform which has the following menus available at the left panel</Text>
                        </PlatformSubhead>
                    </Box>
                </Flex>
            </Container>
        </Section>
        <CardSection>
            <Container width="narrow2">
                <Flex gap={1} variant="responsive">
                    <Box>
                        <Card className={border_radius}>
                            <CardHeader>
                                1. Dashboard
                            </CardHeader>
                            <CardBody >
                                Any commerce faculty or commerce HOD or Principal of a collage can act as Mentor for Skill Development.
                            </CardBody>
                        </Card>
                        <Card className={border_radius}>
                            <CardHeader>
                                2. Modules
                            </CardHeader>
                            <CardBody >
                                There twenty (20) Modules available in the Business Laboratory platform. Details are mentioned in the next section. Student need to start working from module in sequential manner. In otherwords after completion of first module only, system allows to the next module. Accessing each module is very simple. Just pay the money and click the start/resume button. It takes to the module.
                            </CardBody>
                        </Card>
                        <Card className={border_radius}>
                            <CardHeader>
                                3. Registration
                            </CardHeader>
                            <CardBody >
                                Procedure for registraion has been described in this menu.
                            </CardBody>
                        </Card>
                        <Card className={border_radius}>
                            <CardHeader>
                                4. Payments
                            </CardHeader>
                            <CardBody >
                                Procedure for payment of registration and module fee has been enabled in this menu.
                            </CardBody>
                        </Card>
                    </Box>
                    <Box>
                        <Card className={border_radius}>
                            <CardHeader>
                                5. Wallet
                            </CardHeader>
                            <CardBody >
                                After making payments, the amounts will be shown in the wallet which will then be utilized for purchase of modules. This menu will show the details and balance in the wallet account.
                            </CardBody>
                        </Card>
                        <Card className={border_radius}>
                            <CardHeader>
                                6. Referral
                            </CardHeader>
                            <CardBody >
                                Using this menu, student can refer the platform to their friends and network. Subscribers need to enter Name, Mobile and email of the referred person. Module link
                                would be sent to their email or mobile. Using this link referred candidates can regiser and pay for the modules. Upon successful registraion of the referred candidiates, the
                                subscriber will get referral bonus which will be displayed in the wallet account.
                            </CardBody>
                        </Card>
                        <Card className={border_radius}>
                            <CardHeader>
                                7. Profile
                            </CardHeader>
                            <CardBody >
                                This gives the complete particulars about the subscribers.
                            </CardBody>
                        </Card>
                    </Box>
                </Flex>
            </Container>
        </CardSection>
        <Section style={{ background: "radial-gradient(88.77% 227.16% at 12.81% -73.17%, #2B237C 0%, #251C72 31%, #0F054C 100%)", color: "#FFF", transform: "skewY(-5deg)" }}>
            <Container style={{ transform: "skewY(5deg)", color: 'white' }} className={paddingTop} width="narrow2">
                <Flex gap={4} variant="responsive">
                    <Box >
                        <Subhead style={{ color: '#00D4FF', fontWeight: '550', fontSize: '40px' }}>
                            Features <StaticImage src='../../images/Skill_elevator_icon.png' alt="Skill_elevator_icon" width={20} style={{ marginTop: "2.5%" }} />
                        </Subhead>
                        <Subhead style={{ color: '#FFFFFF', fontWeight: '550', fontSize: '40px' }}>
                            for subscriibers
                        </Subhead>
                        <FeatureCard>
                            <Text> <StaticImage src='../images/platform/selfLearn.png' alt="selfLearn" /></Text>
                            <CardHeader className={styles.paddingAllSide}>
                                Self Learn
                            </CardHeader>
                            <Text >
                                Each module has been designed keeping in view of easy learning or self learing in mind. In otherwords, subscriber does not requires any bodies training or support.
                            </Text>
                        </FeatureCard>
                        <FeatureCard>
                            <Text> <StaticImage src='../images/platform/module.png' alt="module" /></Text>
                            <CardHeader>
                                Module completion and Scoring
                            </CardHeader>
                            <Text >
                                {` Upon completion of particular module, platform gives score and time taken to complete the module. Score is mentioned in % which starts from 1 to 100%. 
               
                ` }
                            </Text>
                        </FeatureCard>
                    </Box>
                    <Box>
                        <FeatureCard>
                            <CardHeader style={{ marginTop: '25%' }}>
                            </CardHeader>
                            <Text >
                                Each module has theory and video demo. Using these options student can start using modules eaily. However, subscriber has option to seek helpdesk from BizzLab experts by sending email or calling them.
                            </Text>
                        </FeatureCard>
                        <FeatureCard>
                            <Text >  <StaticImage src='../images/platform/certificate.png'
                                alt="certificate" /></Text>
                            <CardHeader>
                                Certificate Download
                            </CardHeader>
                            <Text >
                                Susbcribers can also dowload certificate of completion of Skill training and experience.
                            </Text>
                        </FeatureCard>
                    </Box>
                </Flex>
            </Container>
        </Section>
        <Section className={black_color} >

            <Container className={paddingTop} width="narrow2">
                <Flex gap={4} variant="responsive">
                    <Box >
                        <Subhead >
                            <span className={spancolor}>Referral Bonus to</span>  Subscriber
                        </Subhead>
                        <Text className={bold}>
                            <StaticImage src="../../images/skill_icon.png" alt="skill_elevator_icon" width={20} style={{ marginTop: "1%" }} /> EARN WHILE YOU REFER TO YOUR FRIENDS
                        </Text>

                        <Box>
                            {
                                referralBonusPoints && referralBonusPoints.map(point => {
                                    return (
                                        <PlatformPlainText >
                                            <FlexList>
                                                <li> <span> <BsCheckCircleFill className={spancolor} /> </span> <br />&nbsp;</li>
                                                <li> <span >  {point} </span></li>
                                            </FlexList>

                                        </PlatformPlainText>
                                    )
                                })
                            }
                        </Box>
                        <Text> <Button className={gardient_btns}><Link to='/Login'>Register & Login  </Link><FiChevronRight /></Button></Text>
                    </Box>
                    <Box>
                        <StaticImage src='../../images/platform/referal_for_subscribers.png' alt="login_creation" width={900} className={marginBottom} />
                    </Box>
                </Flex>
            </Container>
            <Section width="narrow2">
                <Box center style={{ position: "relative" }}>
                    <StaticImage src='../../images/platform/get_started_bizzlab.png' alt="get_started" width={900} />
                </Box>
            </Section>
        </Section>
    </>
)
export default Subscrib
