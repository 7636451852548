import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import PlatformNavList from '../components/platform/nav-items';
import Insight_platform from "../components/Platform_page/insight_platform"
import Available_paltforms from "../components/Platform_page/available_paltforms"
import PlatformHeaderTiles from "../components/platform/platform_header";
import Work_based from "../components/Platform_page/work_based";
import Modules_section from "../components/platform/Bizlab/biz_lab_modules";
import Role_based_simulation from "../components/Platform_page/Role_based_simulation";
import Working_module from "../components/platform/josim_page/working_module";
import Subscrib from "../components/Platform_page/subscrib";
import ContactUS from "../components/contact-us";


const PlatformPage = () => (
  <Layout navName="Platform" >
    <Seo title="Platform" />
    {/* <PlatformNavList navName="Our Platform"/> */}
    <PlatformHeaderTiles header="BizzLab Platform" imageName="platform_banner" />
    <Insight_platform />
    {/* <Available_paltforms /> */}
    <Work_based/>
    <Modules_section/>
    <Role_based_simulation/>
    <Working_module/>
   <Subscrib/>
   <ContactUS/>
  </Layout>
)

export default PlatformPage
