import * as React from "react"
import { Container, Box, Text, Section, Flex, Heading, PlatformHeading, PlatformSubhead, FlexList } from "../ui"
import { StaticImage } from "gatsby-plugin-image"
import { about_hero, about_hero_img, bizzlab, marginBottom_4, marginTop_1, text_justify } from "../ui.css"
export default function Role_based_simulation(props) {
    return (
        <>
     
            <Section  >
                <Container style={{ color: "#081F32" }}  width="narrow2" className={marginTop_1}>
                    <Flex gap={4} variant="responsive">
                        <Box>
                            <FlexList className={bizzlab}>
                                <li> <Text><StaticImage src='../../images/Skill_icon.png' alt="Skill_elevator_icon" width={30} /> </Text></li>
                                <li>
                                    <PlatformHeading>
                                        Role based Simulation
                                    </PlatformHeading>
                                </li>
                            </FlexList>
                            <PlatformSubhead className={marginBottom_4}>
                                An online platform to enhance Accounting employment.
                            </PlatformSubhead>
                        </Box>
                    </Flex>
                    <Flex gap={4} variant="responsive">
                        <Box>
                            <Container  >
                            <Text className={text_justify}>
                                BizzLab also has various working modules that are integrated for
                                various job roles. It has five job roles such as (a) Accounts Officer
                                (b) BPO Specialist © Tax Accountant (d) FP&A (e) Compliance Officer.
                                Each role is created based on what exactly one person does any work
                                once he/she get job. It is consolidation of all possible works that
                                one can do in the Industry. Before starting this module, student has
                                to complete the Business Laboratory which gives different set of work
                                experience as a first step. Using all the modules or transactions process,
                                one can easily understand any job role. After completion of each job student
                                will get a certificate like as an example after completing Accounts Officer
                                Training, he/she gets "Practial On-Job Training Completion Certificate for
                                Accounts Officer" After completion of all 5 Job Training he will be awarded
                                a qualification as " ProFinance Manager" (Equal to 3 years of experianced
                                person in the Industry)
                            </Text>
                            </Container>
                        </Box>
                        <Box>
                            <Text>
                                <StaticImage src="../../images/Josim_images/josim_img2.png" alt="josim_img2" />
                            </Text>
                        </Box>
                    </Flex>
                </Container>
            </Section>
        </>
    )
}
