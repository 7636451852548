import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
// import { AiOutlineArrowRight } from "@react-icons/all-files/fa/";
import { AiOutlineArrowRight } from "react-icons/ai";
import { FaCheckSquare } from "react-icons/fa";
import { FcApproval } from "react-icons/fc";
import { Container, Heading, Section, Flex, Box, Subhead, Text, SubheadSmall, Button } from '../../ui';
import { lightcolor, paddingTop } from '../../ui.css';
const Working_module = () => {
    return (
        <Section style={{ background: "#0F054C", transform: "skewY(-5deg)", color: "#fff" }}>
            <Container style={{ transform: "skewY(5deg)" }} className={paddingTop}>
              <Flex gap={4} variant="responsive">
                
                <Box center>
                    <Subhead >Working Modules for various Job Roles</Subhead>
                    <Text className={lightcolor}>5 Job roles to simulate industry experience</Text>
                </Box>
               
              </Flex>
              <Flex gap={4} variant="responsive">
                  <Box></Box>
                <Box>
                  <Text>
                  <StaticImage src='../../../images/Josim_images/accounts_offers.png' alt="accounts_offers" />
                  </Text>
               
                </Box>
                <Box></Box>
              </Flex>
              <Flex gap={4} variant="responsive">
                  <Box></Box>
                <Box>
                  <Text>
                  <StaticImage src='../../../images/Josim_images/bpo_specialist.png' alt="bpo_specialist" />
                  </Text>
             
                </Box>
                <Box></Box>
              </Flex>
          
              <Flex gap={4} variant="responsive">
                  <Box></Box>
                <Box>
                  <Text>
                  <StaticImage src='../../../images/Josim_images/tax_accountant.png' alt="tax_accountant" />
                  </Text>
              
                </Box>
                <Box></Box>
              </Flex>
          
              <Flex gap={4} variant="responsive">
                  <Box></Box>
                <Box>
                  <Text>
                  <StaticImage src='../../../images/Josim_images/financial_planner_analyst.png' alt="financial_planner_analyst" />
                  </Text>
              
                </Box>
                <Box></Box>
              </Flex>
          
              <Flex gap={4} variant="responsive">
                  <Box></Box>
                <Box>
                  <Text>
                  <StaticImage src='../../../images/Josim_images/store_officer.png' alt="store_officer" />
                  </Text>
              
                </Box>
                <Box></Box>
              </Flex>
          


            </Container>
        </Section>
    )
}
export default Working_module;